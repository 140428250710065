import React from 'react'
import { urlWithSearchParamsHandler, prepareParagraph, sanityImageUrl, clickTrack } from '../../../utils/format'
import { PopupButton } from '@typeform/embed-react'
import { Link } from 'gatsby'
import styles from './style.module.sass'

export default ({ info }) => {
  if (!info) return null

  return (
    <div className={styles.info}>
      <div className={styles.wrap}>
        <div className={styles.customer}>
          <img src={sanityImageUrl(info.image)} alt={info.image?.caption} className={styles.image} />
        </div>
        <div className={styles.text}>
          {info.name && <span className={styles.name}>{info.name}</span>}
          <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: info.title }} />
          {info.rating?.url && (
            <Link className={styles.rating} to={info.rating.url}>
              {info.rating.starsImage && <img className={styles.ratingImage} src={sanityImageUrl(info.rating.starsImage)} alt={info.rating?.text}/>}
              {info.rating.text && <p>{info.rating.text}</p>}
            </Link>
          )}
          <ul className={styles.list}>
            {info.benefits &&
              info.benefits.map((item, index) => (
                <li className={styles.listItem} key={index}>
                  {prepareParagraph(item)}
                </li>
              ))}
          </ul>
          <div className={styles.buttonWrap}>
            {info.buttonUrl?.includes('https://quiz/') ? (
              <PopupButton
                id={buttonUrl.replace('https://quiz/', '')}
                as="a"
                onReady={() => clickTrack(info.buttonText)}
                transitiveSearchParams={true}
                className={styles.button}
              >
                {info.buttonText}
              </PopupButton>
            ) : (
              <a href={info.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.button}>
                {info.buttonText}
              </a>
            )}
          </div>
          <p className={styles.off}>{prepareParagraph(info.offLabels)}</p>
        </div>
      </div>
    </div>
  )
}
